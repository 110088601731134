export default function SubSession(props) {
    const getLabelColor = () => {
        switch (props.currSubSession) {
          case "ncna": return "#3A5FCD"; // Example color for 'no context, no audio'
          case "ycna": return "#3A5FCD"; // Example color for 'with context, no audio'
          case "ycya": return "#FF8C00"; // Example color for 'with context, with audio'
          default: return "#333"; // Default color
        }
      };

    return (
        <p className="video-label" style={{ color: getLabelColor() }}>
            Current Mode: {
            props.currSubSession == "ncna" ? "no context, no audio" :
            props.currSubSession == "ycna" ? "with context, no audio" :
            props.currSubSession == "ycya" ? "with context, with audio" :
            ""
            }
            {  props.currSubSession == "ycya" && " - enable the audio!" 
            
            }</p>        
    )
}