    export function getNumerator(currSession, numCompleted) {
        return numCompleted - (currSession-1) * 101;
    }
    
    export function getDenominator(currSession) {
        if (currSession === 10) {
            return 104;
        }
        return 101;
    } 

    