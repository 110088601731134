function Video(props) {  
  // const { loading, videoUrl, currSubSession, setVideoLoaded } = props;

  return (
    <div className="authenticated-inner-video">
      {props.loading ? (
        <p>Loading...</p>
      ) : (
        props.videoUrl && (
          <div className="video-container">
            {
              props.currSubSession === "ycya" &&  
              <p className="caution-audio" style={{ color: "#FF8C00" }}>
                🎧 Audio must be enabled for this mode! 
              </p>
            }
            <video 
              controls 
              autoPlay
              className="video"
              onCanPlay={() => props.setVideoLoaded(true)}
              onEnded={() => props.setVideoEnded(true)}
            >
              <source src={props.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      )}
    </div>
  )

}

export default Video;