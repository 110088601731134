export function PrimaryButton(props) {
  return (
  <button onClick={props.function} className="btn btn-primary" >
    {props.text}
  </button>
  )
}

export function SecondaryButton(props) {
  return (
    <button onClick={props.function} disabled={props.loading} className="btn btn-secondary">
      {props.text}
    </button>
  )
}

