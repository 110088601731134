import React from 'react';

function ThankYou() {
  return (
    <div className="auth-wrapper">
      <div className="auth-inner-thank-you">
        <h1 className="title-thank-you">Thank You!</h1>
        <h3 className="subtitle-thank-you">You have completed all sessions.</h3>
        <p className="subtitle-thank-you">We appreciate your participation and effort.</p>
        {/* You can add an image or logo here if needed */}
        <img src="path-to-thank-you-image" alt="Thank You" className="App-logo" />
      </div>
    </div>
  );
}

export default ThankYou;