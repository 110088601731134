import { useNavigate } from 'react-router-dom';

export default function Welcome() {
  const navigate = useNavigate();

  function handlePress() {
    navigate('/home');
  }
  
  return (
    <div className="auth-wrapper-survey">
      <div className="auth-inner-welcome">
        <h1 className="welcome-title">Welcome to Video Annotation!</h1>
        
        <ol>
          <li className="welcome-description">There are 1013 videos to annotate in total.</li>
          <li className="welcome-description">
            Each video is about 10 seconds long, but some can last up to 30 seconds.
          </li>
          <li className="welcome-description">
            There are three types of videos in each section, in this order:
            <ul>
              <li>Videos without context, without audio (approx 45%)</li>
              <li>Videos with context, without audio (approx 45%)</li>
              <li>Videos with context, with audio (approx 10%): audio must be enabled for this section!</li>
            </ul>
          </li>
          <li className="welcome-description">
            A few 'repeat' videos are inserted for quality control.
          </li>
          <li className="welcome-description">
            There are 10 sessions, each with about 100 videos.
          </li>
          <li className="welcome-description">
            Please take a 5-minute break between multiple sessions in one sitting.
          </li>
          <li className="welcome-description">
            Please finish all annotation tasks in the same day if possible.
          </li>
          <li className="welcome-description">
            The UI is not optimized for mobile devices. Please use a desktop or laptop.
          </li>
        </ol>

        <p className="welcome-prompt">Click the button below to get started.</p>
        <button onClick={handlePress} className="start-button">Start</button>
      </div>
    </div>
  );
}