import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth, db } from "../firebase";
import { setDoc, doc, collection, query, getDocs, orderBy } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { createVideoList } from "../util/algo";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [loading, setLoading] = useState(false); // New loading state


  const navigate = useNavigate();
  

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true); 

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      console.log(user);

      if (user) {
        
        const videoList = [];
        const q = query(collection(db, "videoList"), orderBy("id", "asc"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          videoList.push(doc.data());
          console.log(doc.id, " => ", doc.data());
        });

        
        const newVideoList = createVideoList(videoList);

        await setDoc(doc(db, "users", user.uid), {
          email: user.email,
          firstName: fname,
          lastName: lname,
          videoList: newVideoList,
          surveyCompleted: false,
          videoCompletedList: [],
          currSession: 0,
          currSubSession: 'ncna',
        });

      }

      navigate('/'); 

      console.log("User Registered Successfully!!");
      toast.success("User Registered Successfully!!", {
        position: "top-center",
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        {loading ? (
          <div className="loading-indicator">Shuffling video list...</div> 
          ) : (
          <form onSubmit={handleRegister}>
            <h3>Sign Up</h3>

            <div className="mb-3">
              <label>First name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                onChange={(e) => setFname(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label>Last name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                onChange={(e) => setLname(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Sign Up
              </button>
            </div>
            <p className="forgot-password text-right">
              Already registered <a href="/login">Login</a>
            </p>
          </form>
        )}
      </div>
    </div>
  );
}
export default Register;