import { useRef, useEffect } from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const savedTimeoutId = useRef();
  const startTime = useRef();
  const counter = useRef(1);

  useEffect(() => {
    savedCallback.current = callback; 
  }, [callback]);

  useEffect(() => {
    function main() {
      const nowTime = Date.now();
      const nextTime = startTime.current + counter.current * delay;
      savedTimeoutId.current = setTimeout(
        main,
        delay - (nowTime - nextTime)
      );
      counter.current += 1;
      savedCallback.current(counter.current);
    }

    if (delay !== null && delay !== 0) {
      startTime.current = Date.now();
      savedTimeoutId.current = setTimeout(main, delay);
      
      return () => {
        clearTimeout(savedTimeoutId.current);
      }
    } else {
      counter.current = 1;
      startTime.current = null;
    }
  }, [delay]);
}

export function secondsToHms(d) {
    d = Number(d);
  
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
  
    if (h === 0) {
      if (m === 0) {
        return `${s}s`;
      } else {
        return `${m}m ${s}s`;
      }
    } else {
      return `${h}h ${m}m ${s}s`;
    }
  }