import { auth, db } from "../firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";


    export async function handleLogout() {
        try {
            await auth.signOut();
            window.location.href = "/login";
            console.log("User logged out successfully!");
        } catch (error) {
          console.error("Error logging out:", error.message);
        }
    }

    export const handleNext = async (videoList) => {
        try {
    
        if (videoList && videoList.length > 0) {
            const [firstVideo, ...updatedVideoList] = videoList;
            console.log("First video removed:", firstVideo);
            const userRef = doc(db, "users", auth.currentUser.uid);
    
            await updateDoc(userRef, {
                videoList: updatedVideoList,
                videoCompletedList: arrayUnion(firstVideo)
            });
    
            console.log("First video removed from the list in Firestore.");
    
        } else {
            console.log("No videos available to remove.");
        }
        
        } catch (error) {
            console.error("Error updating video list:", error);
        }    
    };
