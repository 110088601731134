import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function InstructionNewSession(props) {
    console.log(props.currSession)
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Welcome to New Session!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Session {props.currSession ? props.currSession : 1} includes:</h4>
                <ul>
                    <li>{(!props.currSession || props.currSession < 10) ? 44 : 46} videos without context, without audio</li>
                    <li>{(!props.currSession || props.currSession < 10) ? 44 : 46} videos with context, without audio</li>
                    <li>{(!props.currSession || props.currSession < 10) ? 13 : 12} videos with context, with audio</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Okay</Button>
            </Modal.Footer>
        </Modal>
    )
}