
export const createVideoList = (videoList) => {
  const ncnaVideos = videoList.filter(video => video.path.endsWith('_ncna'));
  const ycnaVideos = videoList.filter(video => video.path.endsWith('_ycna'));
  const ycyaVideos = videoList.filter(video => video.path.endsWith('_ycya'));
  
  const finalList = [];
  const usedPathsGlobal = new Set();
  let order = 1;

  for (let session = 1; session <= 10; session++) {
    // const sessionVideos = [];
    const usedPathsSession = new Set();
    
    const isLastSession = session === 10;
    const ncnaCount = isLastSession ? 42 : 40;
    const ycnaCount = isLastSession ? 42 : 40;
    const ycyaCount = isLastSession ? 11 : 12;
    
    const getRandomVideos = (videos, count, excludePaths) => {
      let available = videos.filter(v => !excludePaths.has(v.path));
      if (available.length < count) {
        available = videos.filter(v => !usedPathsSession.has(v.path));
      }
      return shuffle(available).slice(0, Math.min(count, available.length));
    };

    const shuffle = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const ncnaSelected = getRandomVideos(ncnaVideos, ncnaCount, usedPathsGlobal);
    const ncnaRepeats = shuffle(ncnaSelected).slice(0, 4);

    const ycnaSelected = getRandomVideos(ycnaVideos, ycnaCount, usedPathsGlobal);
    const ycnaRepeats = shuffle(ycnaSelected).slice(0, 4);

    const ycyaSelected = getRandomVideos(ycyaVideos, ycyaCount, usedPathsGlobal);
    const ycyaRepeats = shuffle(ycyaSelected).slice(0, 1);
    
    const combinedSessionVideos = [
      ...ncnaSelected, ...ncnaRepeats, 
      ...ycnaSelected, ...ycnaRepeats,
      ...ycyaSelected, ...ycyaRepeats
    ];

    usedPathsGlobal.add(...ncnaSelected.map(v => v.path));
    usedPathsGlobal.add(...ycnaSelected.map(v => v.path));
    usedPathsGlobal.add(...ycyaSelected.map(v => v.path));

    usedPathsSession.add(...combinedSessionVideos.map(v => v.path));

    combinedSessionVideos.forEach(video => {
      finalList.push({
        order: order++,
        session: session,
        videoId: video.videoId,
        path: video.path
      });
    });
  }
  
  return finalList;
};