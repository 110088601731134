import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { SecondaryButton } from "../components/buttons";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useInterval, secondsToHms } from "../util/time";
import { handleLogout } from "../util/server";
import { ProgressBarComponent } from "../components/progressBar";

import Form from "../components/form";
import Video from "../components/video";
import SubSession from "../components/subSession";
import InstructionAudio from "./instructionAudio";
import InstructionNewSession from "./instructionNewSession";

function Home() {
  const [count, setCount] = useState(0);
  const [delay, setDelay] = useState(null)
  const [userDetails, setUserDetails] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState(null);
  const [currSession, setCurrSession] = useState(0);
  const [currSubSession, setCurrSubSession] = useState('');

  // Set Instruction Modals
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showNewSessionModal, setShowNewSessionModal] = useState(false);

  // Video controls
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);

  // Navigate
  const navigate = useNavigate();

  useInterval(() => {
    setCount((prevCount) => prevCount + 1);
  }, delay);

  const showInstructions = () => {
    const newVideo = videoList[0];
    const newVideoSubSession = newVideo.path.slice(-4);
    const newVideoSession = newVideo.session;

    if (videoList.length >= 1013) {
      setShowNewSessionModal(true);
    }

    if (!currSubSession && !currSession) {
      console.log("First video:");
      setCurrSubSession(newVideoSubSession);
      setCurrSession(newVideoSession);
    }
      
    if (currSubSession && currSubSession !== newVideoSubSession) {
      console.log("New subSession:", newVideoSubSession);
      setCurrSubSession(newVideoSubSession);

      if (currSubSession === 'ncna') {
        alert('You have annotated all videos without context and without audio in this section!');
      } else if (currSubSession === 'ycna') {
        
        alert('You have annotated all videos with context and without audio in this section!');
        setShowAudioModal(true);

      } else if (currSubSession === 'ycya') {
        alert('You have annotated all videos with context and with audio in this section!');
      }
      

      if (currSession && currSession !== newVideoSession) {
        setCurrSession(newVideoSession);
      
        setShowNewSessionModal(true);

      }
    }
  }

  const fetchVideo = async () => {
    setLoading(true);
    setDelay(null);
    setCount(0);

    const newVideo = videoList[0];
    const newVideoPath = newVideo.path;
    const videoRef = ref(storage, `all/${newVideoPath}.mp4`);

    try {
      const url = await getDownloadURL(videoRef);
      setVideoUrl(url);
      
      setDelay(1000);
    } catch (error) {
      console.error("Error fetching video URL:", error);
      setVideoUrl(null);
    } finally {
      setLoading(false);
    }
     
  };

  useEffect(() => {
    if (!auth.currentUser) {
      return
    }

    const userRef = doc(db, "users", auth.currentUser.uid);
    
    const unsubscribe = onSnapshot(userRef, (userDoc) => {
      if (!userDoc.exists()) {
        console.log("No user document found.");
        return;
      }

      const userData = userDoc.data();
      // console.log("User data updated:", userData);

      if (userData.surveyCompleted === undefined || userData.surveyCompleted === false) {
        console.log("User has not completed the survey yet.", userData);
        navigate('/survey');
      }

      setUserDetails(userData);
      // console.log("User details:", userDetails);
      setVideoList(userData.videoList);
    }, (error) => {
      console.error("Error listening to user data updates:", error);
    });

    return () => unsubscribe();

  }, [auth.currentUser]);

  useEffect(() => {
    
    if (!videoList) {
      console.log("home.js useEffect: No video list found.");
      return 
    }
    
    if (videoList.length > 0) {
      console.log("Video list length:", videoList.length);
      
      fetchVideo().then(() => {
        showInstructions();
      })

    } else {
      console.log("home.js useEffect: No videos found.");
      
      navigate('/thank-you');
    }

  }, [videoList]);

  if (!videoList) {
    return <p>Loading...</p>
  }

  return (
    <div className="authenticated-wrapper">
      <div className="nav-inner">
        <Link to="/profile">
          <img
            src="https://img.icons8.com/ios/452/user-male-circle.png"
            alt="profile icon"
            className="profile"
          />
        </Link>
        <h1>Welcome, {userDetails ? userDetails.firstName : "User"}!</h1>
        <SecondaryButton text="Logout" function={handleLogout} loading={loading}/>
      </div>
      
      <ProgressBarComponent 
        numCompleted={1013-videoList.length} 
        currSession={currSession}
        currSubSession={currSubSession} 
      />

      <div className="authenticated-inner">
        {
          userDetails ?
          <>
            { videoUrl && 
              <Video 
                videoUrl={videoUrl}
                loading={loading} 
                currSubSession={currSubSession}
                setVideoLoaded={setVideoLoaded}
                setVideoEnded={setVideoEnded}
              />
            }
            <Form 
              videoList={videoList} 
              currVideoDetails={videoList[0]} 
              userId={auth.currentUser.uid} 
              count={count}
              videoLoaded={videoLoaded} 
              videoEnded={videoEnded} 
              setVideoLoaded={setVideoLoaded} // Add this prop
              setVideoEnded={setVideoEnded} // Add this prop
            />
          </>
          :
          <p>Loading...</p>
        }
      </div>
      <div className="footer-inner-first">
        <SubSession currSubSession={currSubSession} />
        <p className="time-elapsed">Time Elapsed: {secondsToHms(count)}</p>
      </div>
      <div className="footer-inner-second">
        <p className="time-elapsed">There are three modes in Session {currSession}: </p>
        <p className="time-elapsed">- no context, no audio: {currSession === 10 ? 46 : 44}</p>
        <p className="time-elapsed">- with context, no audio: {currSession === 10 ? 46 : 44}</p>
        <p className="time-elapsed">- with context, with audio: {currSession === 10 ? 12: 13}</p>
      </div>

      <InstructionAudio
        show={showAudioModal}
        onHide={() => setShowAudioModal(false)}
      />

      <InstructionNewSession
        currSession={currSession}
        show={showNewSessionModal}
        onHide={() => setShowNewSessionModal(false)}
      />
      
    </div>
  );
}

export default Home;