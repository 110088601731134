import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function Profile() {

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const user = auth.currentUser;

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setProfile(docSnap.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfile();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!profile) {
    return <div>No profile information available.</div>;
  }

  return (
    <div className="authenticated-profile-wrapper">
      <div className="profile-nav-inner">  
        <h2>User Profile</h2>
      </div>
      <div className="authenticated-profile-inner">
        <div className="profile-item">
          <strong>First Name:</strong> {profile.firstName}
        </div>
        <div className="profile-item">
          <strong>Last Name:</strong> {profile.lastName}
        </div>
        <div className="profile-item">
          <strong>Email:</strong> {profile.email}
        </div>
        <div className="profile-item">
          <strong>Race:</strong> {profile.race}
        </div>
        <div className="profile-item">
          <strong>Age:</strong> {profile.age}
        </div>
        <div className="profile-item">
          <strong>Gender:</strong> {profile.gender}
        </div>

        {/* Additional Fields */}
        <div className="profile-item">
          <strong>Confidence Level:</strong> {profile.confidenceLevel}
        </div>
        <div className="profile-item">
          <strong>Experience Type:</strong> {profile.experienceType}
        </div>
        <div className="profile-item">
          <strong>Home Country:</strong> {profile.homeCountry}
        </div>
        <div className="profile-item">
          <strong>Owner Type:</strong> {profile.ownerType}
        </div>
        <div className="profile-item">
          <strong>Professional Type:</strong> {profile.professionalType}
        </div>
        <div className="profile-item">
          <strong>Resident Country:</strong> {profile.residentCountry}
        </div>
        <div className="profile-item">
          <strong>Years of Ownership:</strong> {profile.yearsOwnership}
        </div>
        <div className="profile-item">
          <strong>Years of Professional Experience:</strong> {profile.yearsProfessionalExperience}
        </div>

        {profile.videoCompletedList && (
          <div className="profile-item">
            <strong>Progress:</strong> {profile.videoCompletedList.length} videos annotated
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;