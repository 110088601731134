import ProgressBar from "@ramonak/react-progress-bar";
import { getNumerator, getDenominator } from "../util/session";
import { formatNumber } from "../util/string";
import { useEffect, useState } from "react";


export function ProgressBarComponent(props) {

    // Determine the color based on the current subSession
    const getLabelColor = () => {
        switch (props.currSubSession) {
        case "ncna": return "#3A5FCD"; // Example color for 'no context, no audio'
        case "ycna": return "#3A5FCD"; // Example color for 'with context, no audio'
        case "ycya": return "#FF8C00"; // Example color for 'with context, with audio'
        default: return "#333"; // Default color
        }
    };

    return (
        <div className="header-inner">
            <p className="header-text">Current Session: {props.currSession} </p>
            <ProgressBar 
                completed={Math.floor(getNumerator(props.currSession, props.numCompleted)/getDenominator(props.currSession)*100)}
                height="14px"
                labelSize="10px"
                bgColor={getLabelColor()}
            />
            <div className="progress-bar-info">
                <p className="header-text progress-footer">{formatNumber(getNumerator(props.currSession, props.numCompleted))} / {getDenominator(props.currSession)} (current session)</p>
                <p className="header-text progress-footer">{formatNumber(props.numCompleted)} / {formatNumber(1013)} (all 10 sessions)</p>
            </div>
        </div>
    );
}
