import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "./screens/login";
import SignUp from "./screens/register";
import Profile from "./screens/profile";
import Home from "./screens/home";
import Survey from "./screens/survey";
import ThankYou from './screens/thankYou';
import Welcome from './screens/welcome';


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { auth } from "./firebase"; 

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // auth.signOut();
    auth.onAuthStateChanged((user) => {
      
      setUser(user);
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={user ? <Navigate to="/home" /> : <Login />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/thank-you" element={<ThankYou />} />

        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;