import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase";

function Survey() {
  const [race, setRace] = useState("");
  const [age, setAge] = useState(20);
  const [gender, setGender] = useState("");
  const [residentCountry, setResidentCountry] = useState("");
  const [homeCountry, setHomeCountry] = useState("");
  const [confidenceLevel, setConfidenceLevel] = useState(5);
  const [experienceType, setExperienceType] = useState("");
  const [ownerType, setOwnerType] = useState("");
  const [yearsOwnership, setYearsOwnership] = useState("");
  const [professionalType, setProfessionalType] = useState("");
  const [yearsProfessionalExperience, setYearsProfessionalExperience] = useState("");

  const navigate = useNavigate();

  const handleSurveySubmit = async (e) => {
    e.preventDefault();
    console.log("Survey submit click!!");

    if (!race || !age || !gender || !residentCountry || !homeCountry ||
      !confidenceLevel || !experienceType || !ownerType ||
      !yearsOwnership || !professionalType || !yearsProfessionalExperience) {
    alert("Please fill in all fields.");
    return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        await updateDoc(doc(db, "users", user.uid), {
          race: race,
          age: age,
          gender: gender,
          residentCountry: residentCountry,
          homeCountry: homeCountry,
          confidenceLevel: confidenceLevel,
          experienceType: experienceType,
          ownerType: ownerType,
          yearsOwnership: yearsOwnership,
          professionalType: professionalType,
          yearsProfessionalExperience: yearsProfessionalExperience,
          surveyCompleted: true,
        });

        console.log("Survey Submitted Successfully!!");

        toast.success("Survey Submitted Successfully!!", {
          position: "top-center",
        });

        navigate('/welcome'); // or any appropriate route
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="auth-wrapper-survey">
      <div className="auth-inner-survey">
        <form onSubmit={handleSurveySubmit}>
          <h3>User Survey (Required)</h3>

          <div className="mb-3">
            <label>Race</label>
            <select
              className="form-control"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              required
            >
              <option value="" disabled>Enter Race</option>
              <option value="caucasian">Caucasian</option>
              <option value="asian">Asian</option>
              <option value="african descent">African Descent</option>
              <option value="hispanic">Hispanic</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="mb-3">
            <label>Age</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter age"
              onChange={(e) => setAge(Number(e.target.value))}
              min="0"
              required
            />
          </div>

          <div className="mb-3">
            <label>Gender</label>
            <select
              className="form-control"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="" disabled>Enter Gender</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non-binary">Non-binary</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="mb-3">
            <label>Resident Country</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter resident country"
              onChange={(e) => setResidentCountry(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label>Home Country</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter home country"
              onChange={(e) => setHomeCountry(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label>Confidence Level to Detect Dog Emotion: {confidenceLevel}</label>
            <input
              type="range"
              className="form-range"
              min="1"
              max="10"
              step="1"
              value={confidenceLevel}
              onChange={(e) => setConfidenceLevel(Number(e.target.value))}
              required
            />
            <div className="d-flex justify-content-between">
              <span>1 - No Confidence</span>
              <span>10 - Extremely Confident</span>
            </div>
          </div>

          <div className="mb-3">
            <label>Experience Type</label>
            <select
              className="form-control"
              value={experienceType}
              onChange={(e) => setExperienceType(e.target.value)}
              required
            >
              <option value="" disabled>Enter Experience Type</option>
              <option value="none">None</option>
              <option value="owner">Owner</option>
              <option value="expert/professional">Expert/Professional</option>
            </select>
          </div>

          <div className="mb-3">
            <label>Owner Type</label>
            <select
              className="form-control"
              value={ownerType}
              onChange={(e) => setOwnerType(e.target.value)}
              required
            >
              <option value="" disabled>Enter Owner Type</option>
              <option value="none">None</option>
              <option value="primary owner">Primary Owner</option>
              <option value="secondary owner">Secondary Owner (Family Member's, Partner's)</option>
            </select>
          </div>

          <div className="mb-3">
            <label>Years of Ownership</label>
            <select
              className="form-control"
              value={yearsOwnership}
              onChange={(e) => setYearsOwnership(e.target.value)}
              required
            >
              <option value="" disabled>Enter Years of Ownership</option>
              <option value="1 year or less">1 Year or Less</option>
              <option value="1-3 years">1-3 Years</option>
              <option value="3 or more years">3 or More Years</option>
              <option value="none">None</option>
            </select>
          </div>

          <div className="mb-3">
            <label>Professional Type</label>
            <select
              className="form-control"
              value={professionalType}
              onChange={(e) => setProfessionalType(e.target.value)}
              required
            >
              <option value="" disabled>Enter Professional Type</option>
              <option value="vet">Vet</option>
              <option value="trainer">Trainer</option>
              <option value="dog walker">Dog Walker</option>
              <option value="dog sitter">Dog Sitter</option>
              <option value="none">None</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="mb-3">
            <label>Years of Professional Experience</label>
            <select
              className="form-control"
              value={yearsProfessionalExperience}
              onChange={(e) => setYearsProfessionalExperience(e.target.value)}
              required
            >
              <option value="" disabled>Enter Years of Professional Experience</option>
              <option value="1 year or less">1 Year or Less</option>
              <option value="1-3 years">1-3 Years</option>
              <option value="3 or more years">3 or More Years</option>
              <option value="none">None</option>
            </select>
          </div>

          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Submit Survey
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Survey;