// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore, addDoc, collection, getDocs } 
from 'firebase/firestore';
import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC14I4sRKI_BUgugJEXqDrmDO4ULXjMSwc",
  authDomain: "video-annotation-4f452.firebaseapp.com",
  projectId: "video-annotation-4f452",
  storageBucket: "video-annotation-4f452.firebasestorage.app",
  messagingSenderId: "948707047323",
  appId: "1:948707047323:web:6e591e5c49de548578d33e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the storage service for use in the app

const auth = getAuth(app);
export { auth };
export const storage = getStorage(app);
export const db = getFirestore(app);