import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function InstructionAudio(props) {
    const [correctNumber, setCorrectNumber] = useState(null);
    const [options, setOptions] = useState([]);
    const [message, setMessage] = useState(null);

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const generateNewQuestion = () => {
        // Generate random two-digit number
        const number = generateRandomNumber(10, 99);
        setCorrectNumber(number);

        // Generate 2 other random numbers for options
        let numberOptions = [number];
        while (numberOptions.length < 3) {
            const newNum = generateRandomNumber(10, 99);
            if (!numberOptions.includes(newNum)) {
                numberOptions.push(newNum);
            }
        }
        // Shuffle options
        numberOptions.sort(() => Math.random() - 0.5);
        setOptions(numberOptions);
        setMessage(new SpeechSynthesisUtterance(number));
        
    };

    const playAudio = () => {
        window.speechSynthesis.speak(message);
    }

    useEffect(() => {
        if (props.show) {
            generateNewQuestion();
        }
    }, [props.show]);

    const handleNumberClick = (number) => {
        if (number === correctNumber) {
            props.onHide();
        } else {
            generateNewQuestion();
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Audio Number Game
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Listen and Choose!</h4>
                <p>
                    Listen to the number and click the correct button below.
                </p>
                <div className="d-flex justify-content-around mt-4">
                    {options.map((number, index) => (
                        <Button 
                        key={index}
                        variant="primary"
                        size="lg"
                        onClick={() => handleNumberClick(number)}
                        >
                            {number}
                        </Button>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button     variant="danger"  onClick={playAudio}>Play Audio</Button>
            </Modal.Footer>
        </Modal>
    );
}