import { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { handleNext } from "../util/server";

const annotationsRef = collection(db, "annotations");

function Form(props) {
  const [happyLevel, setHappyLevel] = useState(0);
  const [neutralLevel, setNeutralLevel] = useState(0);
  const [angryLevel, setAngryLevel] = useState(0);
  const [fearfulLevel, setFearfulLevel] = useState(0);
  const [otherLevel, setOtherLevel] = useState(0);
  const [otherDescription, setOtherDescription] = useState("");
  const [isAnyEmotionSelected, setIsAnyEmotionSelected] = useState(false);

  useEffect(() => {
    setIsAnyEmotionSelected(
      [happyLevel, neutralLevel, angryLevel, fearfulLevel].some(
        (level) => level > 0
      ) || (otherLevel > 0 && otherDescription.trim().length > 0)
    );
  }, [happyLevel, neutralLevel, angryLevel, fearfulLevel, otherLevel, otherDescription]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!props.videoEnded) {
      alert("Please watch the full video before submitting.");
      return;
    }

    if (!isAnyEmotionSelected) {
      alert("Please select at least one emotion.");
      return;
    }
  
    if (otherLevel > 0 && otherDescription.trim().length === 0) {
      alert("Please provide a description for 'Other' emotion.");
      return;
    }
  
    const choices = {
      happy: happyLevel,
      neutral: neutralLevel,
      angry: angryLevel,
      fearful: fearfulLevel,
      other: {
        level: otherLevel,
        description: otherLevel > 0 ? otherDescription : "",
      }
    };
  
    try {    
      await addDoc(annotationsRef, {
        choices,
        createdAt: new Date(),
        timeTaken: props.count,
        videoPath: props.currVideoDetails.path,
        userId: props.userId,
        videoId: props.currVideoDetails.videoId,
      });
  
      // Reset states after submission
      setHappyLevel(0);
      setNeutralLevel(0);
      setAngryLevel(0);
      setFearfulLevel(0);
      setOtherLevel(0);
      setOtherDescription("");

      // Reset video states
      props.setVideoLoaded(false);
      props.setVideoEnded(false); 
  
      handleNext(props.videoList);
      
      console.log("Emotion Submitted");
    } catch (error) {
      console.error("Error submitting emotion:", error);
    }
  };

  if (!props.currVideoDetails || !props.videoLoaded) {
    return (
      <div className="authenticated-inner-form">
        <h3 className="title">No video to annotate</h3>
      </div>
    );
  }

  return (
    <div className="authenticated-inner-form">
      <form onSubmit={handleSubmit} className="form">
        
        <h3 className="title">Select the emotion(s) displayed by the dog in the video:</h3>
        <h5 className="subtitle">For each emotion chosen, rate its intensity (1- lowest, 5- strongest)</h5>
        
        {/* Happy Emotion */}
        <label className="checkbox-item">
          <input
            type="checkbox"
            checked={happyLevel > 0}
            onChange={() => setHappyLevel(happyLevel > 0 ? 0 : 3)}
          />
          <p className="checkbox-text">Happy {happyLevel > 0 && `- Lv. ${happyLevel}`}</p>
          {happyLevel > 0 && (
            <div className="slider-level">
              <input
                type="range"
                className="form-range"
                min="0"
                max="5"
                step="1"
                value={happyLevel}
                onChange={(e) => setHappyLevel(Number(e.target.value))}
                required
              />
              
            </div>
          )}
        </label>
        
        {/* Neutral Emotion */}
        <label className="checkbox-item">
          <input
            type="checkbox"
            checked={neutralLevel > 0}
            onChange={() => setNeutralLevel(neutralLevel > 0 ? 0 : 3)}
          />
          <p className="checkbox-text">Neutral {neutralLevel > 0 && `- Lv. ${neutralLevel}`}</p>
          {neutralLevel > 0 && (
            <div className="slider-level">
              
              <input
                type="range"
                className="form-range"
                min="0"
                max="5"
                step="1"
                value={neutralLevel}
                onChange={(e) => setNeutralLevel(Number(e.target.value))}
                required
              />
              
            </div>
          )}
        </label>
        
        {/* Angry/Aggressive Emotion */}
        <label className="checkbox-item">
          <input
            type="checkbox"
            checked={angryLevel > 0}
            onChange={() => setAngryLevel(angryLevel > 0 ? 0 : 3)}
          />
          <p className="checkbox-text">Angry {angryLevel > 0 && `- Lv. ${angryLevel}`}</p>
          {angryLevel > 0 && (
            <div className="slider-level">
          
              <input
                type="range"
                className="form-range"
                min="0"
                max="5"
                step="1"
                value={angryLevel}
                onChange={(e) => setAngryLevel(Number(e.target.value))}
                required
              />
              
            </div>
          )}
        </label>
        
        {/* Fearful Emotion */}
        <label className="checkbox-item">
          <input
            type="checkbox"
            checked={fearfulLevel > 0}
            onChange={() => setFearfulLevel(fearfulLevel > 0 ? 0 : 3)}
          />
          <p className="checkbox-text">Fearful {fearfulLevel > 0 && `- Lv. ${fearfulLevel}`}</p>
          {fearfulLevel > 0 && (
            <div className="slider-level">
              
              <input
                type="range"
                className="form-range"
                min="0"
                max="5"
                step="1"
                value={fearfulLevel}
                onChange={(e) => setFearfulLevel(Number(e.target.value))}
                required
              />
              
            </div>
          )}
        </label>

        {/* Other Emotion */}
        <label className="other-checkbox-item">
          <div className="checkbox-item">
            <input
              type="checkbox"
              checked={otherLevel > 0}
              onChange={() => setOtherLevel(otherLevel > 0 ? 0 : 3)}
            />
            <p className="checkbox-text">Other {otherLevel > 0 && `- Lv. ${otherLevel}`}</p>
            {otherLevel > 0 && (
              <div className="slider-level">                
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="5"
                  step="1"
                  value={otherLevel}
                  onChange={(e) => setOtherLevel(Number(e.target.value))}
                  required
                />  
              </div>
            )}
          </div>
          {otherLevel > 0 && 
            <input
              type="text"
              className="form-control text-input"
              placeholder="Describe the emotion"
              value={otherDescription}
              onChange={(e) => setOtherDescription(e.target.value)}
            />
          }
        </label>

        <div className="btn-container">
          <button 
            type="submit" 
            className={`btn ${isAnyEmotionSelected && props.videoEnded ? 'btn-primary' : 'btn-secondary'}`}
            // disabled={!props.videoEnded}
          >
            Submit
          </button>
        </div>

      </form>
    </div>
  );
}

export default Form;